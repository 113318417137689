// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message: 'Dev env.',
  firebaseConfig : {
    apiKey: "AIzaSyCY5wF4zXmogMBTbImh4pHaIDZDg9vVE9g",
    authDomain: "jujitsu-v2.firebaseapp.com",
    databaseURL: "https://jujitsu-v2.firebaseio.com",
    projectId: "jujitsu-v2",
    storageBucket: "jujitsu-v2.appspot.com",
    messagingSenderId: "1012657765964",
    appId: "1:1012657765964:web:4ef24e50337821ee767ee9",
    measurementId: "G-7RCN9N0WP8"
  },
  
  SERVER_URL: 'http://localost:4200'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
