import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { FirebaseAnalytics, FirebaseAnalyticsPlugin } from "@capacitor-community/firebase-analytics";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

/**
 * Service used in application to seralize generic methods
 */
export class JujitsuService {

  private _storage: Storage | null = null;

  /**
   * @ignore
   */
  constructor(
    private translate: TranslateService,
    private storage: Storage) {
      this.initStorage();
      //this.initFB();
  }
  
  /**
   * Set the new translate language in application
   * 
   * @example
   * setTranslateLanguage('en')
   * 
   * @param {string} sLang the new lang to set
   */
  public setTranslateLanguage(sLang:string): void {
    this.translate.use(sLang);
  }
  
  /**
   * Get a translate value by a given key
   *
   * @param {string} sKey the translate key
   * @return {any} The translate value
   */
   getTranslate(sKey:string): any {
    return this.translate.instant(sKey);
  }

  /**
   * Get the current language used in the application
   *
   * @returns {string} The current language
   */
  getTranslateCurrentLang(): string {
    return this.translate.currentLang;
  }

  /**
   * Set a key/value pair
   * 
   * @example
   * setValue('key', 'value')
   * 
   * @param {string} sKey the key
   * @param {any} sValue the value
   */
   public setValue(sKey:string, sValue:any): void {
    this._storage?.set(sKey, sValue).then((response) => {
      // do nothing;
    }).catch((error) => {
      console.log('An error occure for ' + sKey + ' with value ' + sValue + ' return ' + error);
    });
  }

  /**
   * Get a stored boolean value
   *
   * @param {string} sKey The stored key
   * @return {boolean} The stored value
   */
  public async getBoolValue(sKey:string): Promise<boolean> {
    const result = await this._storage?.get(sKey);
    if (result) {
      return Boolean(result);
    } else {
      return false;
    }
  }

  /**
   * Get a stored number value
   *
   * @param {string} sKey The stored key
   * @return {number} The stored value
   */
  public async getNumberValue(sKey:string, sDefault:number = 0): Promise<number> {
    const result = await this._storage?.get(sKey);
    if (result) {
      return Number(result);
    } else {
      return sDefault;
    }
  }

  /**
   * Get / filter technic to show on current component
   * 
   * @example
   * getTechs(array[], 6, true, 'koshi')
   * 
   * @param {any[]} sData The technic
   * @param {number} sBeltColor The matched belt color
   * @param {boolean} sWithSearch With search action
   * @param {string} sSearchTerm With search term
   * @returns {any[]} An array with localized filtered technic (color & search term) 
   */
  public getTechs(sData:any[], sBeltColor:number = 6, sWithSearch:boolean = false, sSearchTerm:string = ''): any[] {
    let tCpt:number = 0;
    let tArrayTech:any[] = [];
    let tArrayTechData:any[] = [];
    let rArray:any[] = [];

    for ( let i = 0; i < sData.length; i++) {
      let tFamilleAll = sData[i];
      let tTech:any[] = [];

      let tFamille:string = "";
      if (tFamilleAll.hasOwnProperty('title') && typeof tFamilleAll.title === 'string') {
        tFamille = this.getTranslate(tFamilleAll.title);
      }
      
      let tDiscipline:string = "";
      if (tFamilleAll.hasOwnProperty('discipline') && typeof tFamilleAll.discipline === 'string') {
        tDiscipline = this.getTranslate(tFamilleAll.discipline);
      }

      for (let k = 0; k < tFamilleAll.techniques.length; k++) {
        let tTechnique = tFamilleAll.techniques[k];

        let tMatchSearch:boolean = true;
        if(sWithSearch) {
          tMatchSearch = false;
          if (tTechnique.hasOwnProperty('name')) {
            let tName:string = tTechnique.name;
            tName = tName.replace(/-/g, ' ').toLowerCase();
            if (tName.search(sSearchTerm.toLowerCase()) >= 0) {
              tMatchSearch = true;
            }
          }
        }

        let tMatchColor:boolean = true;
        if (tTechnique.hasOwnProperty('color')) {
          let tColor:number = Number(tTechnique.color);
          if (tColor > sBeltColor) {
            tMatchColor = false;
          }
        }

        if (tMatchColor && tMatchSearch) {
          let tTechDesc:string = "";
          if (tTechnique.hasOwnProperty('key')) {
            tTechDesc = this.getTranslate(tTechnique.key);
          }

          let tPicture:string = "";
          if (tTechnique.image !== "") {
            tPicture = "/assets/" + tTechnique.image;
          }

          tArrayTechData.push({famille:tFamille, name:tTechnique.name, desc:tTechDesc, picture:tPicture});
          tTech.push({name:tTechnique.name, picture:tPicture, famille:tFamille, discipline:tDiscipline, id:tCpt});
          
          tCpt++;
        }
      }

      if(tTech.length > 0) {
        tArrayTech.push({famille:tFamille, tech:tTech});
      }
    }

    // Create an array with a dictionary of 2 values: data & tech
    rArray.push({data:tArrayTechData, tech:tArrayTech});

    return rArray;
  }

  /**
   * Initialise languages used in the app,
   * then set default language if none is found
   * and finally set the language used by the device as the language to used
   */
  public initTranslateLanguage(): void {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');

    let tBrowserLang = this.translate.getBrowserLang();
    let tLang = tBrowserLang.match(/en|fr/) ? tBrowserLang : 'en';

    this.setTranslateLanguage(tLang);
    //this.setTranslateLanguage('en');
  }

  /**
   * Enable or diseable Firebase Analytics
   * Firebase / Google Analytic
   * 
   * @example
   * SetCollection(true)
   * 
   * @param {boolean} sValue The boolean value
   */
  public SetCollection(sValue:boolean): void {
    FirebaseAnalytics.setCollectionEnabled({enabled: sValue});
  }

  /**
   * Add a new page event (user navigation on menu).
   * Firebase / Google Analytic
   * 
   * @example
   * addPageEvent('goshin')
   * 
   * @param {string} sKey The key event
   */
  public addPageEvent(sKey:string): void {
    FirebaseAnalytics.logEvent({name: 'page_view', params: {page: sKey}});
  }

  /**
   * Add a new network event (user interaction on a network link).
   * Firebase / Google Analytic
   * 
   * @example
   * addNetworkEvent('youtube')
   * 
   * @param {string} sKey The key event
   */
  public addNetworkEvent(sKey:string): void {
    FirebaseAnalytics.logEvent({name: 'network', params: {type: sKey}});
  }

  /**
   * Add a new help event (user interaction on information button).
   * Firebase / Google Analytic
   * 
   * @example
   * addHelpEvent('home')
   * 
   * @param {string} sKey The key event
   */
  public addHelpEvent(sKey:string): void {
    FirebaseAnalytics.logEvent({name: 'popup_help', params: {categorie: sKey}});
  }

  /**
   * Add a new picture event (user interaction on a technique name).
   * Firebase / Google Analytic
   * 
   * @example
   * addPictureEvent('home')
   * 
   * @param {string} sKey The key event
   */
  public addPictureEvent(sKey:string) {
    FirebaseAnalytics.logEvent({name: 'picture', params: {name: sKey}});
  }

  /**
   * Init Firebase befor using it
   * Firebase / Google Analytic
   * 
   * @example
   * initFB()
   */
  public async initFB() {
    console.log("init FB");
    const result = await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    console.log("done Init FB", result);
  }

  private async initStorage() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const result = await this.storage.create();
    this._storage = result;
  }
}