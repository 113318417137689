import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';

import { JujitsuService } from '../app/services/jujitsu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  public appPages = [];

  constructor(
    private iab: InAppBrowser,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private platform: Platform,
    private translate: TranslateService,
    private mService: JujitsuService) {
      this._initializeApp();
  }

  share(): void {
    this.iab.create('market://details?id=com.whiteronin.jujitsu', '_system');
    this.mService.addNetworkEvent('RateMyApp');
  }

  private _initializeApp(): void {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();
      this._initTranslate();
      this._initMenu();
      this._checkEnvironment();
      this.mService.initFB().then(() => {
        this.mService.SetCollection(true);
      });
    });
  }

  private _initTranslate(): void {
    this.mService.initTranslateLanguage();
  }

  private _initMenu(): void {
    this._addMenuEntry('PAGE.HOME.MENU', '/home', 'home');
    this._addMenuEntry('PAGE.JUDO.MENU', '/judo', 'list');
    this._addMenuEntry('PAGE.JUJITSU.MENU', '/jujitsu', 'list');
    this._addMenuEntry('PAGE.GOSHIN.MENU', '/goshin', 'list');
    this._addMenuEntry('PAGE.NAGE-NO-KATA.MENU', '/nagenokata', 'list');
    this._addMenuEntry('PAGE.ATEMI.MENU', '/atemi', 'list');
    this._addMenuEntry('PAGE.LEXIQUE.MENU', '/lexique', 'list');
    this._addMenuEntry('PAGE.SEARCH.MENU', '/search', 'search');
    this._addMenuEntry('PAGE.BUSHIDO.MENU', '/bushido', 'bookmark');
    this._addMenuEntry('PAGE.CREDITS.MENU', '/credits', 'information-circle');
    this._addMenuEntry('PAGE.NETWORK.MENU', '/aboutme', 'share');
  }

  private _addMenuEntry(sKey:string, sUrl:string, sIcon:string): void {
    this.translate.get(sKey).subscribe(
      value => {
        // value is our translated string
        this.appPages.push({title: value, url: sUrl, icon: sIcon});
      }
    )
  }

  private _checkEnvironment(): void {
      console.log(environment.message + " prod:" + environment.production);
      console.log("firebase data: " + environment.firebaseConfig.authDomain);
  }
}
