import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',       loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'judo',       loadChildren: () => import('./pages/judo/judo.module').then( m => m.JudoPageModule)},
  { path: 'jujitsu',    loadChildren: () => import('./pages/jujitsu/jujitsu.module').then( m => m.JujitsuPageModule)},
  { path: 'goshin',     loadChildren: () => import('./pages/goshin/goshin.module').then( m => m.GoshinPageModule)},
  { path: 'nagenokata', loadChildren: () => import('./pages/nagenokata/nagenokata.module').then( m => m.NageNoKataPageModule)},
  { path: 'atemi',      loadChildren: () => import('./pages/atemi/atemi.module').then( m => m.AtemiPageModule)},
  { path: 'lexique',    loadChildren: () => import('./pages/lexique/lexique.module').then( m => m.LexiquePageModule)},
  { path: 'search',     loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)},
  { path: 'bushido',    loadChildren: () => import('./pages/bushido/bushido.module').then( m => m.BushidoPageModule)},
  { path: 'credits',    loadChildren: () => import('./pages/credits/credits.module').then( m => m.CreditsPageModule)},
  { path: 'aboutme',    loadChildren: () => import('./pages/aboutme/aboutme.module').then( m => m.AboutmePageModule)},

  { path: 'list', loadChildren: () => import('./pages/list/list.module').then( m => m.ListPageModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
